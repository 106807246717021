import Typed from "typed.js";


const button = document.querySelector("#submission-button");
const close_button = document.querySelector("#close-button");
const form = document.querySelector("#submission-form");

let next_session = "Wed. September 11th - Z-Bar, Awards Screening";

const typed = new Typed('#next-session', {
    strings: [next_session],
    typeSpeed: 30,
    onComplete: () => {
        console.log("done");
    }
  });

close_button.addEventListener("click", () => {
    button.style.display = "block";
    form.style.display = "none";
});

button.addEventListener("click", () => {
    button.style.display = "none";
    form.style.display = "flex";
});
